<script lang="ts">
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();
</script>

<div class="blur" on:click={() => dispatch('close')} />
<div class="modal-outer">
  <slot />
</div>

<style>
  .blur {
    top: 0;
    left: 0;
    position: fixed;
    min-width: 100vw;
    min-height: 100vh;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    z-index: 2;
  }
  .modal-outer {
    position: fixed;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    border-radius: 4px;
    z-index: 3;
    padding: 2em;
  }
</style>
