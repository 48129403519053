<script lang="ts">
  import ModalBase from './ModalBase.svelte';
  import { getAddress } from '../api/reqs';
  import { latestTransactionStore } from '../stores.js';
  import QRCode from 'qrcode';

  export let showDepositModal: boolean;
  let message = '';
  let address: string;
  let addressInput;
  let showCopied = false;
  let showSuccess = false;
  let qrImageString = '';

  latestTransactionStore.subscribe((val: { address: string }) => {
    if (val && val.address == address) showSuccess = true;
  });

  const submit = async () => {
    address = await getAddress({
      message,
    });
    qrImageString = await QRCode.toDataURL(address);
  };

  const addressClicked = () => {
    addressInput.select();
    const copied = document.execCommand('copy');
    if (copied) {
      showCopied = true;
      setTimeout(() => (showCopied = false), 1500);
    }
  };
</script>

<ModalBase on:close={() => (showDepositModal = false)}>
  <h1>Deposit</h1>
  {#if showSuccess}
    <h2>Deposit received!</h2>
  {:else if address}
    <img src={qrImageString} alt="qrcode" />
    <div class="input-container">
      <input
        bind:this={addressInput}
        on:click={addressClicked}
        type="text"
        value={address}
        readonly
      />
      {#if showCopied}
        <span class="copied scale-up-center">copied</span>
      {/if}
    </div>
  {:else}
    <label for="message"> leave a message </label>
    <input bind:value={message} id="message" type="text" />
    <button on:click={submit}>Submit</button>
  {/if}
</ModalBase>

<style>
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(1) translateX(-50%);
      transform: scale(1) translateX(-50%);
      opacity: 1;
    }
    60% {
      -webkit-transform: scale(1.5) translateX(-25%);
      transform: scale(1.5) translateX(-25%);
      top: 30px;
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1.5) translateX(-25%);
      transform: scale(1.5) translateX(-25%);
      top: 30px;
      opacity: 0;
    }
  }
  @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(1) translateX(-50%);
      transform: scale(1) translateX(-50%);
      opacity: 1;
    }
    60% {
      -webkit-transform: scale(1.5) translateX(-25%);
      transform: scale(1.5) translateX(-25%);
      top: 30px;
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1.5) translateX(-25%);
      transform: scale(1.5) translateX(-25%);
      top: 30px;
      opacity: 0;
    }
  }
  input {
    width: 100%;
  }
  .copied {
    position: absolute;
    display: block;
    left: 50%;
    transform: translateX(-50%);
    top: 5px;
  }
  .scale-up-center {
    -webkit-animation: scale-up-center 1.5s cubic-bezier(0.39, 0.575, 0.565, 1)
      both;
    animation: scale-up-center 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  .input-container {
    position: relative;
  }
</style>
