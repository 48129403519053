export default async (endpoint, body, options) => {
    const res = await fetch(`API_URL/${endpoint}`, {
        method: (options === null || options === void 0 ? void 0 : options.method) || 'GET',
        body: JSON.stringify(body) || null,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    if (!res.ok)
        throw res.statusText;
    return await res.json();
};
