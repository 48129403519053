<script>
  import { onMount, onDestroy } from 'svelte';

  export let token = null;
  let mounted = false;
  let loaded = false;
  let widgetID;

  window.hcaptchaOnLoad = () => {
    loaded = true;
  };

  window.onSuccess = (tok) => {
    token = tok;
  };

  onMount(() => {
    mounted = true;
  });

  onDestroy(() => {
    window.hcaptchaOnLoad = null;
    window.onSuccess = null;
    if (loaded) hcaptcha = null;
  });

  $: if (mounted && loaded) {
    widgetID = hcaptcha.render(`h-captcha-1`, {
      sitekey: 'CAPTCHA_KEY',
      theme: 'light',
      callback: 'onSuccess',
      'error-callback': 'onError',
      'close-callback': 'onClose',
    });
  }
</script>

<svelte:head>
  <script
    src="https://js.hcaptcha.com/1/api.js?onload=hcaptchaOnLoad"
    async
    defer>
  </script>
</svelte:head>

<form on:submit|preventDefault={() => {}}>
  <div id="h-captcha-1" />
</form>

<style>
</style>
