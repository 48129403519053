import makeApiRequest from './makeApiRequest';
export const getBalance = async () => {
    const res = await makeApiRequest('balance');
    return res.balance;
};
export const getAddress = async (body) => {
    const res = await makeApiRequest('invoice', body, { method: 'POST' });
    return res.address;
};
export const getDeposits = async () => {
    const res = await makeApiRequest('deposits');
    return res;
};
export const requestWithdrawal = async (address, token) => {
    const res = await makeApiRequest('withdraw', { address, captchaToken: token }, { method: 'POST' });
    return res;
};
