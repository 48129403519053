<script>
  import { createEventDispatcher } from 'svelte';
  import { fade } from 'svelte/transition';

  export let disabled = false;
  let showTooltip = false;

  const dispatch = createEventDispatcher();

  const click = () => {
    if (disabled) return;
    dispatch('click');
  };

  const mouseEnter = () => {
    if (!disabled) return;
    showTooltip = true;
  };

  const mouseLeave = () => {
    if (!disabled) return;
    showTooltip = false;
  };
</script>

<div class="container">
  {#if showTooltip}
    <div class="tooltip" transition:fade={{ duration: 150 }}>
      Balance too low
    </div>
  {/if}
  <div
    on:mouseenter={mouseEnter}
    on:mouseleave={mouseLeave}
    class="btn"
    class:disabled
  >
    <a on:click={click} href="javascript:void(0)">
      <slot />
    </a>
  </div>
</div>

<style>
  .container {
    position: relative;
    display: inline-block;
    margin: 0 0.6em 15px 0.6em;
    box-sizing: content-box;
  }
  .disabled {
    opacity: 0.65;
  }
  .disabled > a {
    cursor: not-allowed;
  }
  .tooltip {
    position: absolute;
    top: -29px;
    z-index: 2;
    color: white;
    width: 100%;
    background: grey;
    border-radius: 4px;
    padding: 3px;
  }
  .tooltip::after {
    content: ' ';
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: grey transparent transparent transparent;
  }
  .btn {
  }

  a {
    top: 0;
    box-sizing: content-box;
    color: white;
    font-family: Helvetica, sans-serif;
    font-weight: bold;
    font-size: 30px;
    width: 134px;
    text-align: center;
    text-decoration: none;
    background-color: var(--sol-purple);
    display: block;
    position: relative;
    padding: 20px 40px;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    text-shadow: 0px 1px 0px #000;
    filter: drop-shadow(0px, 1px, #000);

    -webkit-box-shadow: inset 0 1px 0 var(--sol-purple-dark),
      0 10px 0 var(--sol-purple-dark);
    -moz-box-shadow: inset 0 1px 0 var(--sol-purple-dark),
      0 10px 0 var(--sol-purple-dark);
    box-shadow: inset 0 1px 0 var(--sol-purple-dark),
      0 10px 0 var(--sol-purple-dark);

    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }

  a:active {
    top: 10px;
    background-color: var(--sol-purple);

    -webkit-box-shadow: inset 0 1px 0 var(--sol-purple-dark),
      inset 0 -3px 0 var(--sol-purple-dark);
    -moz-box-shadow: inset 0 1px 0 var(--sol-purple-dark),
      inset 0 -3pxpx 0 var(--sol-purple-dark);
    box-shadow: inset 0 1px 0 var(--sol-purple-dark),
      inset 0 -3px 0 var(--sol-purple-dark);
  }

  .btn:after {
    box-sizing: content-box;
    content: '';
    height: 100%;
    width: 100%;
    padding: 4px;
    position: absolute;
    bottom: -15px;
    left: -4px;
    z-index: -1;
    background-color: #2b1800;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }
</style>
