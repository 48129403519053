<script lang="ts">
  import { onMount } from 'svelte';
  import { getBalance, getDeposits } from './api/reqs';
  import BigButton from './components/BigButton.svelte';
  import DepositModal from './components/DepositModal.svelte';
  import DepositsTable from './components/DepositsTable.svelte';
  import WithdrawModal from './components/WithdrawModal.svelte';
  import { latestTransactionStore } from './stores.js';

  let showDepositModal = false;
  let showWithdrawModal = false;
  let balance = 0;
  let deposits = [];
  let ws: WebSocket;
  let balUp = false;
  let balDown = false;
  let disableWithdraw = false;

  onMount(async () => {
    balance = await getBalance();
    deposits = await getDeposits();
    ws = new WebSocket('WS_URL');
    ws.onmessage = ({ data: msg }) => {
      msg = JSON.parse(msg);
      if (msg.eventName == 'balanceUpdate') {
        msg.data.balance > balance ? (balUp = true) : (balDown = true);
        setTimeout(() => {
          balUp = false;
          balDown = false;
        }, 1200);
        balance = msg.data.balance;
      }
      if (msg.eventName == 'newDeposit') {
        latestTransactionStore.set(msg.data);
        msg.data.new = true;
        deposits.push(msg.data);
        deposits = deposits;
      }
    };
  });

  $: {
    disableWithdraw = balance < 0.000255 ? true : false;
  }
</script>

<main>
  {#if showDepositModal}
    <DepositModal bind:showDepositModal />
  {:else if showWithdrawModal}
    <WithdrawModal bind:showWithdrawModal />
  {/if}

  <h1>Welcome to Solana Faucet</h1>
  <h2 class="bal" class:bal-up={balUp} class:bal-down={balDown}>
    {balance} SOL
  </h2>
  <div class="btn-container">
    <BigButton on:click={() => (showDepositModal = true)}>Deposit</BigButton>
    <BigButton
      bind:disabled={disableWithdraw}
      on:click={() => (showWithdrawModal = true)}>Withdraw</BigButton
    >
  </div>
  <p id="description">
    Solana Faucet is a community jar to test sending and receiving free SOL with
    your Solana wallet.
    <br />
    You can deposit as often as you like but you can only withdraw once per hour.
  </p>
  <DepositsTable {deposits} />
</main>

<style>
  main {
    text-align: center;
    padding: 0;
    min-width: 100vw;
    min-height: 100vh;
    margin: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  h1 {
    color: var(--sol-green);
  }
  .bal {
    color: white;
    transition: color 1s;
  }
  .bal-up {
    color: #0ccb0c;
  }
  .bal-down {
    color: #f52f2f;
  }
  .btn-container {
    margin: 1.5em 0;
  }
  #description {
    color: white;
    max-width: 450px;
  }
</style>
