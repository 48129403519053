<script lang="ts">
  import ModalBase from './ModalBase.svelte';
  import { requestWithdrawal } from '../api/reqs';
  import Captcha from './Captcha.svelte';

  export let showWithdrawModal: boolean;
  let address = '';
  let success = false;
  let token = null;
  let error = null;
  const submit = async () => {
    if (!token || !address) return;
    const res = await requestWithdrawal(address, token).catch((err) => {
      error = err;
    });
    if (res) success = true;
  };
</script>

<ModalBase on:close={() => (showWithdrawModal = false)}>
  <h1>Withdraw</h1>
  {#if !success}
    <label for="address">Address</label>
    <input id="address" bind:value={address} type="text" />
    <button disabled={token == null || address.length < 25} on:click={submit}
      >Submit</button
    >
    {#if error}
      <span class="error">{error}</span>
    {/if}
    <Captcha bind:token />
  {:else}
    <h2>successful!</h2>
  {/if}
</ModalBase>

<style>
  .error {
    color: red;
  }
</style>
