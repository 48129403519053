<script lang="ts">
  import * as timeago from 'timeago.js';

  export let deposits: Array<{
    paidAmount: number;
    message: string;
    timeStamp: number;
    new?: boolean;
  }>;

  $: {
    deposits = deposits.sort(({ paidAmount: a }, { paidAmount: b }) => b - a);
    deposits = deposits.slice(0, 5);
    if (deposits.find((d) => d.new == true)) {
      setTimeout(() => {
        deposits = deposits.map((d) => {
          d.new = false;
          return d;
        });
      }, 1500);
    }
  }
</script>

<div class="container">
  <h3>Recent Deposits</h3>
  {#each deposits as deposit}
    <p class:new={deposit.new}>
      <span class="amount">{deposit.paidAmount} SOL &nbsp;</span>
      <span>{deposit.message}</span>
      <br />
      <span class="timestamp">{timeago.format(deposit.timeStamp)}</span>
    </p>
  {/each}
</div>

<style>
  .container {
    color: white;
    margin-bottom: 2em;
  }
  p {
    transition: background-color 0.6s;
  }
  .new {
    background-color: #0cad0c;
  }
  .amount {
    color: var(--sol-blue);
  }
  .timestamp {
    font-style: italic;
    color: #b1b1b1;
  }
</style>
